.About {
    font-size: 1rem;
    background-color: #c0c0c0;
    border: 0.125em solid #dfdfdf;
    border-right-color: #808080;
    border-bottom-color: #808080;
    padding: 1.25em;
    box-shadow: inset 0.0625em 0.0625em #ffffff, 0.125em 0.125em #000000;
    max-width: 50em;
    margin: 1.25em auto;
}

.About h1 {
    background-color: #000080;
    color: #ffffff;
    padding: 0.25em 0.5em;
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 1rem;
}

.About div{
    padding: 1rem;
}

.About p {
    margin-bottom: 1em;
    line-height: 1.4;
}

.About ul {
    list-style-type: square;
    padding-left: 1.25em;
    margin-bottom: 1em;
}

.About li {
    margin-bottom: 0.5em;
}
.About a{
    color: #0000a6d4;
    font-size: 1.1rem;
}