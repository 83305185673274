.create-messenger {
  width: 55%;
  min-width: 20rem;
  margin: 2rem auto ;
  background-color: #c0c0c0;
  border: 0.125rem solid;
  border-color: #ffffff #808080 #808080 #ffffff;
  box-shadow: 0.0625rem 0.0625rem 0 #000000;
  font-size: 0.75rem;
}

.window-body {
  padding: 0.75rem;
}

.error-box {
  background-color: #ffffff;
  border: 0.0625rem solid #ff0000;
  color: #ff0000;
  padding: 0.5rem;
  margin-bottom: 0.75rem;
  font-size: 1rem;
}

.field-row {
  align-items: flex-start;
  display: flex;
  margin-bottom: 0.75rem;
}
.field-row:last-child{
  justify-content: center;
}
.field-row:last-child button{
  font-size: 1.2rem;
}

.field-row label {
  font-size: 0.9rem;
  flex: 0 0 8rem;
}

.field-row input,
.field-row textarea {
  width: 95%;
  flex: 1;
  border: 0.125rem inset #ffffff;
  background-color: #ffffff;
  padding: 0.25rem;
  font-size: 0.9rem;
}

.field-row textarea {
  min-height: 13.5rem;
  height: auto;
  resize: vertical;
}

.field-row .messenger-creator{
  font-size: 1rem;
  font-weight: bold;
  color: #d54545;
}

.input-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.char-count {
  font-size: 0.625rem;
  color: #808080;
  text-align: right;
  margin-top: 0.2rem;
  margin-left: 0.5rem;
}

@media screen and (max-width: 768px) {
  .create-messenger {
    width: 95%;
    min-width: unset;
    margin: 1rem auto;
  }

  .field-row {
    flex-direction: column;
    align-items: stretch;
  }

  .field-row label {
    flex: none;
    margin-bottom: 0.5rem;
  }

  .input-wrapper {
    width: 100%;
  }

  .field-row textarea {
    height: 20rem;
  }
}