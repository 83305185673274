.profile-container {
    max-width: 600px;
    margin: 2rem auto;
    padding: 1rem;
    background-color: #e4e4e4;
    border: 0.125rem solid #7c7c7c;
    font-family: 'Perfect DOS VGA 437', 'Courier New', monospace;
}

.profile-header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.profile-photo {
    width: 8rem;
    height: 8rem;
    margin-right: 1rem;
    overflow: hidden;
    border: 0.125rem solid #7c7c7c;
}

.profile-photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.default-photo {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #7c7c7c;
    color: #ffffff;
    font-size: 4rem;
}

.profile-username {
    font-size: 2rem;
    margin: 0;
}

.profile-info {
    border-top: 0.0625rem solid #7c7c7c;
    padding-top: 1rem;
}

.profile-info p {
    margin: 0.5rem 0;
}

.profile-error, .profile-loading {
    text-align: center;
    margin-top: 2rem;
    font-size: 1.2rem;
}
.edit-profile-button {
    margin-top: 1rem;
    font-size: 1.3rem;
    padding: 0.4rem;
}