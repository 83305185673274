/* Webkit browsers (Chrome, Safari, newer versions of Opera) */
::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}

::-webkit-scrollbar-track {
  background: #c0c0c0;
  border-left: 1px solid #868686;
  border-right: 1px solid #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #c0c0c0;
  border: 2px solid;
  border-color: #ffffff #868686 #868686 #ffffff;
  box-shadow: inset 2px 2px #dfdfdf, inset -2px -2px #808080;
}

::-webkit-scrollbar-button {
  display: block;
  height: 16px;
  width: 16px;
  background: #c0c0c0;
  border: 1px solid;
  border-color: #ffffff #868686 #868686 #ffffff;
  box-shadow: inset 2px 2px #dfdfdf, inset -2px -2px #808080;
}

::-webkit-scrollbar-button:vertical:start:decrement {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='4,6 12,6 8,2' fill='black'/%3E%3C/svg%3E");
}

::-webkit-scrollbar-button:vertical:end:increment {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='4,10 12,10 8,14' fill='black'/%3E%3C/svg%3E");
}


/* base styles */
@font-face {
  font-family: 'Perfect DOS VGA 437';
  src: url('./assets/fonts/Perfect\ DOS\ VGA\ 437.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
* {
  margin: 0;
  font-family: 'Perfect DOS VGA 437', Times;
  color: #2b2b2b;
  background-color: #e4e4e4;
}

/* global button styles */
.retro-button {
  text-decoration: none;
  text-align: center;
  
  background: #e4e4e4;
    border-bottom: 3px inset rgba(0,0,0,.9);
    border-right: 3px inset rgba(0,0,0,.9);
    border-left: 3px inset rgba(255,255,255,.9);
    border-top: 3px inset rgba(255,255,255,.9);
    box-sizing: border-box;
    color: #0f0f0f;

    cursor: pointer;
    display: inline-block;
  font-weight: bold;
}
.retro-button:hover,
.retro-button:hover *{
  color: #003bcf;
  background: #f1f1f1;
}
.retro-button:active,
.retro-button:active *{
    background: #999999; 
}
/* retro window style */
.title-bar {
  background: linear-gradient(90deg, #000080, #1084d0);
  padding: 0.1875rem 0.125rem 0.1875rem 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-bar-text {
  background-color: unset ;
  color: white;
  font-weight: bold;
  font-size: 1.15rem;
}

.title-bar-controls {
  display: flex;
}

.title-bar-controls button {
  width: 1.1rem;
  height: 1.1rem;
  font-size: 0.625rem;
  line-height: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.title-bar-controls button::before {
  content: '\2715';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.close-button {
  text-decoration: none;
  text-align: center;
  
  background: #e4e4e4;
    border-bottom: 2px inset rgba(0,0,0,.9);
    border-right: 2px inset rgba(0,0,0,.9);
    border-left: 2px inset rgba(255,255,255,.9);
    border-top: 2px inset rgba(255,255,255,.9);
    box-sizing: border-box;
    color: #0f0f0f;

    cursor: pointer;
    display: inline-block;
  font-weight: bold;

  
}
.close-button:hover,
.close-button:hover *{
  background: #c6c6c6;
}
.close-button:active,
.close-button:active *{
  border-left: 2px inset rgba(0,0,0,.9);
  border-top: 2px inset rgba(0,0,0,.9);
  border-right: 2px inset rgba(255,255,255,.9);
  border-bottom: 2px inset rgba(255,255,255,.9);
}
/* required input fields */


