/* login page */
.login{
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4rem;
  }
  .login span{
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  .login span button {
    margin-top: 0;
  }
  .login h2{
    text-align: center;
    margin-bottom: 1.8rem;
    font-size: 1.9rem;
  }
  .login form{
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
  }
  .login form label{
    margin-bottom: 0.2rem;
    font-weight: bold;
    font-size: 1.2rem;
  }
  .login form input {
    margin-bottom: 1.3rem;
    font-size: 1rem;
    max-width: 12rem;
    font-family: monospace;
    border: 2px solid #191919;
    background-color: #f7f7f7;
  }
  .login button {
    display: inline-flex;
    align-items: center;
    margin-top: 0.5rem;
    font-size: 1.3rem;
  }
  .login button img{
    margin: 0.1rem 0 0.1rem 0.25rem;
    max-height: 1.5rem;
  }
  .login .logo{
    border-bottom: 0.2rem inset rgba(0,0,0,.9);
    border-right: 0.2rem  inset rgba(0,0,0,.9);
    border-left: 0.2rem  inset rgba(255,255,255,.9);
    border-top: 0.2rem  inset rgba(255,255,255,.9);
    max-width: 8rem;
    margin: 2rem;
    padding: 0.35rem;
  }