.edit-profile-container {
    max-width: 40rem;
    margin: 2rem auto;
    padding: 1rem;
    background-color: #e4e4e4;
    border: 0.125rem solid #7c7c7c;
}

.edit-profile-container h2 {
    font-size: 2rem;
    margin: 0 0 1rem 0;
}

.edit-profile-container form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.edit-profile-container label {
    display: block;
    margin-bottom: 0.5rem;  
}

.edit-profile-container input {
    font-family: Arial;
    letter-spacing: 0.08rem;
    width: calc(100% - 1rem);
    padding: 0.5rem;
    font-size: 1rem;
    border: 0.0625rem solid #7c7c7c;
    background-color: #ffffff;
}


.edit-profile-options .edit-button {
    padding: 0.5rem;
    width: 60%;
}
.edit-profile-container button {
    padding: 0.5rem;
    font-size: 1rem;
    width: 60%;
}


.edit-profile-container .error {
    color: #ff0000;
    margin-bottom: 1rem;
}

.edit-profile-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
}