.header {
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: bold;
    margin: 0 auto;
    font-size: 0.9rem;
    min-height: 1.2rem;
    max-height: 1.2rem;
    background-color: #002d9e;
  }
.header h4{
    font-family: monospace;
    background-color: #002d9e;
    color: #f7f7f7;
}
.header .username{
    margin-left: auto;
    margin-right: 0.15rem;
}
.header img {
    height: 0.9rem;
    margin-right: 0.3rem;
    margin-left: 0.2rem;
    background-color: transparent;
} 
.navbar {
    padding: 2.5rem 1.5rem 1.5rem 1.5rem;
    display: flex;
    align-items: center;
    max-width: 70%;
    margin: 0 auto;
    border-bottom: 2px solid #2c2c2c;
}
.navbar h1 {
    color: #003bcf;
    font-weight: bold;
    font-size: 2.5rem;
  
}
.navbar .links .home {
    display: flex;
    align-items: center;
} 
.navbar .links {
    margin-left: auto;
    display: flex;
    align-items: center;
}
.navbar .underline{
    text-decoration: underline;
}
.navbar .link {

    min-width: 9rem;
    padding: .5rem;
    text-transform: uppercase;
    width: auto;
    height: 2.7rem;
    font-size: 1.3rem;   
}
.navbar img{
    max-height: 4rem;
    margin-right: 0.75rem;
}
.navbar .links img{
    max-height: 2rem;
    margin-right: 1.1rem;
}
.content {
    max-width: calc(70% + 3rem);
    margin: auto;
}

/* footer */
.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
    padding: 0.5rem;
    z-index: 1000;
}

.footer .menu-container {
    position: relative;
    display: inline-block;
}

.footer .menu-button {
    display: flex;
    align-items: bottom;
    font-size: 1.5rem;
    padding: 0.25rem 0.5rem;
}

.footer .menu-button img {
    background: unset;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 0.5rem 0.1rem 0;
}

.footer .menu-dropdown {
    position: absolute;
    bottom: 100%;
    left: 0;
    background-color: #c0c0c0;
    border: 0.125rem solid #808080;
    border-top: 0.125rem solid #ffffff;
    border-left: 0.125rem solid #ffffff;
    box-shadow: 0.125rem 0.125rem 0 #ffffff;
    min-width: 10rem;
    overflow: hidden;
}

.footer .menu-dropdown a,
.footer .menu-dropdown button {
    font-size: 1.1rem;
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;
    color: #000000;
    background-color: transparent;
    border: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    white-space: nowrap;
}

.footer .menu-dropdown a:hover,
.footer .menu-dropdown button:hover {
    background-color: #000080;
    color: #ffffff;
}

@media screen and (max-width: 600px) {
    .navbar {
      flex-direction: column;
      align-items: center;
      padding: 1.5rem;
    }
  

  
    .navbar .links {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  
    .navbar .link {
      width: 100%;
      margin-bottom: 0.5rem;
      text-align: left;
    }
  
    .navbar img {
      max-height: 3rem;
    }
  
    .navbar .links img {
      max-height: 1.5rem;
      margin-right: 0.5rem;
    }
  
    /* Adjust footer for mobile */
    .footer {
      padding: 0.25rem;
    }
  
    .footer .menu-button {
      font-size: 1.2rem;
      padding: 0.2rem 0.4rem;
    }
  
    .footer .menu-button img {
      width: 1.2rem;
      height: 1.2rem;
    }
  
    .footer .menu-dropdown {
      left: 0;
      right: 0;
      width: auto;
    }
  }