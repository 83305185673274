.loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem 0 2rem 0;
}

.loading-box {
    background-color: #c0c0c0;
    border: 2px solid #ffffff;
    border-right-color: #808080;
    border-bottom-color: #808080;
    padding: 20px;
    box-shadow: 2px 2px 0 #000000;
    text-align: center;
}

.loading-text {
    background: unset;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: #050505;
}

.loading-bar {
    width: 200px;
    height: 20px;
    background-color: #ffffff;
    border: 2px inset #808080;
    overflow: hidden;
}

.loading-bar-progress {
    width: 0;
    height: 100%;
    background-color: #050597;
    animation: loading 3s linear infinite;
}

.startup-message {
    margin-top: 20px;
    padding: 10px;
    background-color: #ffffe0;
    border: 1px solid #000000;
    max-width: 300px;
    text-align: center;
    font-size: 0.9rem;
    color: #000000;
}

@keyframes loading {
    0% {
        width: 0;
    }
    50% {
        width: 100%;
    }
    100% {
        width: 0;
    }
}