/* messenger details page */
.messenger-details.terminal-interface {
  font-family: 'Perfect DOS VGA 437', 'Courier New', monospace;
  background-color: #e4e4e4;
  color: #2b2b2b;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  box-sizing: border-box;
  margin: 0;
  border: 0.125rem solid #7c7c7c;
  overflow: hidden;
}

.messenger-details.terminal-interface * {
  background-color: #e4e4e4;
  color: #2b2b2b;
}

.terminal-header {
  border-bottom: 0.0625rem solid #7c7c7c;
  padding-bottom: 0.625rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.terminal-header h2 {
  font-size: 1.5em;
  margin: 0 0 0.5em 0;
  word-break: break-all;
}

.terminal-header p {
  font-size: 1em;
  margin: 0 0 0.5em 0;
}

.header-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.description-toggle, .delete-btn, .fullscreen-btn {
  background: #e4e4e4;
  border-bottom: 0.1875rem inset rgba(0,0,0,.9);
  border-right: 0.1875rem inset rgba(0,0,0,.9);
  border-left: 0.1875rem inset rgba(255,255,255,.9);
  border-top: 0.1875rem inset rgba(255,255,255,.9);
  box-sizing: border-box;
  color: #0f0f0f;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.3125rem 0.625rem;
}

.description-toggle:hover, .delete-btn:hover, .fullscreen-btn:hover {
  color: #003bcf;
  background: #f1f1f1;
}

.description-toggle:active, .delete-btn:active, .fullscreen-btn:active {
  background: #999999;
}

.description {
  border: 0.0625rem solid #7c7c7c;
  padding: 0.625rem;
  margin-top: 0.5em;
  max-height: 10em;
  overflow-y: auto;
}

.terminal-body {
  flex-grow: 1;
  display: flex;
  overflow: hidden;
  border: 0.0625rem solid #7c7c7c;
}

.sidebar {
  width: 200px;
  border-right: 0.0625rem solid #7c7c7c;
  padding: 0.625rem;
  overflow-y: auto;
}

.connected-users h3 {
  font-size: 1em;
  margin-bottom: 0.5rem;
  color: #003bcf;
}

.connected-users ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.connected-users li {
  padding: 0.25rem 0;
}

.messages-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 0.625rem;
}

.message {
  display: flex;
  margin-bottom: 1.1rem;
  line-height: 1.4;
  align-items: flex-start;
}

.profile-pic {
  width: 2.6rem;
  height: 2.6rem;
  margin-right: 0.5rem;
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
  flex-shrink: 0;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #7c7c7c;
}

.message-content {
  flex: 1;
  min-width: 0;
}

.message-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 0.25rem;
}

.username {
  color: #003bcf;
  font-weight: bold;
  text-decoration: none;
}

.username:hover {
  text-decoration: underline;
}

.timestamp {
  color: #666;
  font-size: 0.8em;
  white-space: nowrap;
}

.message-text {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  max-width: 100%;
}

.terminal-footer {
  border-top: 0.0625rem solid #7c7c7c;
  padding-top: 0.625rem;
  margin-top: 0.625rem;
}

.message-form {
  display: flex;
  margin-bottom: 0.3125rem;
}

.message-form .prompt {
  color: #2b2b2b;
  margin-right: 0.625rem;
}

.message-form input {
  flex-grow: 1;
  background-color: #f7f7f7;
  border: 0.0625rem solid #7c7c7c;
  color: #2b2b2b;
  font-family: 'Perfect DOS VGA 437', 'Courier New', monospace;
  font-size: 1em;
  padding: 0.3125rem;
}

.message-form input:focus {
  outline: none;
  border-color: #003bcf;
}

.message-form button {
  background: #e4e4e4;
  border-bottom: 0.1875rem inset rgba(0,0,0,.9);
  border-right: 0.1875rem inset rgba(0,0,0,.9);
  border-left: 0.1875rem inset rgba(255,255,255,.9);
  border-top: 0.1875rem inset rgba(255,255,255,.9);
  box-sizing: border-box;
  color: #0f0f0f;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.3125rem 0.625rem;
  margin-left: 0.625rem;
}

.message-form button:hover {
  color: #003bcf;
  background: #f1f1f1;
}

.message-form button:active {
  background: #999999;
}

.character-count {
  text-align: right;
  font-size: 0.8em;
  color: #666;
  margin-bottom: 0.625rem;
}

.disconnected-message {
  background-color: #ff0000;
  color: #ffffff;
  text-align: center;
  padding: 0.5rem;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.confirm-delete {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #e4e4e4;
  border: 2px solid #7c7c7c;
  padding: 1.25rem;
  z-index: 1000;
}

.confirm-delete p {
  margin-bottom: 0.9375rem;
}

.confirm-delete button {
  margin-right: 0.625rem;
  padding: 0.3125rem 0.625rem;
  background: #e4e4e4;
  border-bottom: 0.1875rem inset rgba(0,0,0,.9);
  border-right: 0.1875rem inset rgba(0,0,0,.9);
  border-left: 0.1875rem inset rgba(255,255,255,.9);
  border-top: 0.1875rem inset rgba(255,255,255,.9);
  color: #0f0f0f;
  cursor: pointer;
}

.confirm-delete button:hover {
  color: #003bcf;
  background: #f1f1f1;
}

.confirm-delete button:active {
  background: #999999;
}

.messenger-details.terminal-interface {
  transition: all 0.3s ease;
}

.messenger-details.terminal-interface.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  border: none;
  padding: 0;
}

.messenger-details.terminal-interface.full-screen .terminal-header,
.messenger-details.terminal-interface.full-screen .terminal-body,
.messenger-details.terminal-interface.full-screen .terminal-footer {
  padding: 0.4rem;
}

.messenger-details.terminal-interface.full-screen .terminal-body {
  height: calc(100vh - 130px);  
}

.message-group {
  display: flex;
  margin-bottom: 1.1rem;
  line-height: 1.4;
  align-items: flex-start;
}

.message-group .profile-pic {
  width: 2.6rem;
  height: 2.6rem;
  margin-right: 0.5rem;
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
  flex-shrink: 0;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #7c7c7c;
}

.message-group .message-content {
  flex: 1;
  min-width: 0;
}

.message {
  margin-bottom: 0.2rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0.15rem;
  border-radius: 0.25rem;
}

.message-text {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  max-width: calc(100% - 70px);
}

.message.mention {
  background-color: rgb(236, 236, 117);
}

.message.mention .message-text {
  background-color: rgb(236, 236, 117);
}
.message.mention .timestamp {
  background-color: rgb(236, 236, 117);
}

.timestamp {
  color: #666;
  font-size: 0.8em;
  white-space: nowrap;
  margin-left: 0.5rem;
  align-self: flex-end;
}

/* Responsive styles */
@media (max-width: 768px) {
  .messenger-details.terminal-interface {
    padding: 0.5rem;
  }

  .terminal-header {
    flex-direction: column;
  }

  .header-buttons {
    margin-top: 0.5rem;
  }

  .terminal-body {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    border-right: none;
    border-bottom: 0.0625rem solid #7c7c7c;
    max-height: 100px;
  }

  .messages-container {
    height: 0;
    flex-grow: 1;
  }

}

@media (max-width: 480px) {
  .messenger-details.terminal-interface {
    padding: 0.25rem;
  }

  .terminal-header h2 {
    font-size: 1.2em;
  }

  .message-group .profile-pic {
    width: 2rem;
    height: 2rem;
  }

  .username {
    font-size: 0.9em;
  }

  .message {
    flex-direction: column;
    align-items: flex-start;
  }

  .message-text {
    max-width: 100%;
  }

  .timestamp {
    align-self: flex-end;
    margin-top: 0.2rem;
    margin-left: 0;
  }
}